// Composables
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/plugins/vuex'

const routes = [
	{
		path: '/',
		component: () => import('@/layout/AppLayout.vue'),
		children: [
			{
				path: '',
				name: 'AppsView',
				component: () => import('@/views/AppsView.vue'),
				meta: {
					logo: null,
					appsButton: false,
					requiresAuth: true
				}
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/layout/AppLayout.vue'),
		children: [
			{
				path: '',
				name: 'LoginView',
				component: () => import('@/views/LoginView.vue'),
				meta: {
					logo: null,
					appsButton: false,
					requiresAuth: false
				}
			}
		]
	},
	{
		path: '/account',
		component: () => import('@/layout/AppLayout.vue'),
		children: [
			{
				path: '',
				name: 'AccountView',
				component: () => import('@/views/AccountView.vue'),
				meta: {
					title: 'Account',
					logo: null,
					appsButton: true,
					requiresAuth: true
				}
			}
		]
	},
	{
		path: '/sketch',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'SketchView',
				component: () => import('@/views/SketchView.vue'),
				meta: {
					title: 'Sketchurizer',
					subtitle: 'Preview',
					logo: '/logo/sketchurizer.png',
					appsButton: true,
					requiresAuth: true
				}
			}
		]
	},
	{
		path: '/model',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'ModelView',
				component: () => import('@/views/ModelView.vue'),
				meta: {
					title: 'Modulator',
					subtitle: 'Preview',
					logo: '/logo/modulator.png',
					appsButton: true,
					requiresAuth: true
				}
			}
		]
	},
	{
		path: '/text',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'TextView',
				component: () => import('@/views/TextView.vue'),
				meta: {
					title: 'Texturizer',
					subtitle: 'Preview',
					logo: '/logo/prototype.png',
					appsButton: true,
					requiresAuth: true
				}
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	
	// set title of page
    document.title = to.meta.title ? 'GenerIO | ' + to.meta.title : 'GenerIO'

	// set loading state
	store.dispatch('app/activateLoading')

	// handle authentication
    const isAuthenticated = store.getters['app/isAuthenticated']
    if (to.meta.requiresAuth && !isAuthenticated) {
        next({ name: 'LoginView' })
    } else if (to.name === 'LoginView' && isAuthenticated) {
        next({ name: 'AppsView' })
    } else {
        next()
    }
})

export default router