// vuex.js state for Texturizer

// mode
const MODE_RGB = 'rgb'
const MODE_DEPTH = 'depth'
const MODE_MESH = 'mesh'
const MODE_DEFAULT = MODE_RGB


export const texturizer = {
    namespaced: true,
    state: {
        mode: MODE_DEFAULT
    },
    mutations: {
        SET_MODE(state, mode) {
            state.mode = mode
        }
    },
    getters: {
        keyModeDefault: () => MODE_DEFAULT,
        keyModeRGB: () => MODE_RGB,
        keyModeDepth: () => MODE_DEPTH,
        keyModeMesh: () => MODE_MESH,
        getMode: (state) => state.mode,
        isModeDefault: (state) => state.mode === MODE_DEFAULT,
        isModeRGB: (state) => state.mode === MODE_RGB,
        isModeDepth: (state) => state.mode === MODE_DEPTH,
        isModeMesh: (state) => state.mode === MODE_MESH,
    },
    actions: {
        setMode({ commit }, mode) {
            commit('SET_MODE', mode)
        },
        setModeToDefault({ commit }) {
            commit('SET_MODE', MODE_DEFAULT)
        },
        setModeToRGB({ commit }) {
            commit('SET_MODE', MODE_RGB)
        },
        setModeToDepth({ commit }) {
            commit('SET_MODE', MODE_DEPTH)
        },
        setModeToMesh({ commit }) {
            commit('SET_MODE', MODE_MESH)
        }
    }
}