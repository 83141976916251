// vuex.js state of app

import { jwtDecode } from "jwt-decode"
import { useCookies } from '@vueuse/integrations/useCookies'
import { updateAccentColors } from '@/plugins/vuetify'

const DESIGN_MODE_LIGHT = 'Light'
const DESIGN_MODE_DARK = 'Dark'
const DESIGN_MODE_DEFAULT = DESIGN_MODE_LIGHT

const DESIGN_THEME_DEFAULT = 0

const COOKIE_EXPIRES = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000) // one year

export const app = {
    namespaced: true,
    state: {
        // base
        version: '',
        appname: '',

        // loading
        loading: {
            active: false,
            progress: -1 // 0..100 shows progress
        },

        // experimental features (only for developer)
        experimental: false,

        // security
        token: null,
        permanent: true,
        username: 'unauthorized',
        developer: false,
        permission: {     
            sketchurizer: false,
            modulator: false,
            texturizer: false
        },

        // design
        design: {
            mode: DESIGN_MODE_DEFAULT,
            theme: DESIGN_THEME_DEFAULT,
            vuetify: null
        }
    },
    mutations: {
        SET_VERSION(state, version) 
        {
            state.version = version
        },
        SET_APPNAME(state, appname) 
        {
            state.appname = appname
        },
        SET_LOADING(state, { active, progress }) 
        {
            state.loading.active = active
            state.loading.progress = progress
        },
        SET_EXPERIMENTAL(state, experimental) 
        {
            state.experimental = experimental
        },
        SET_TOKEN(state, token) 
        {
            // reset state
            state.token = null
            state.username = 'unauthorized'
            state.developer = false
            state.permission.sketchurizer = false
            state.permission.modulator = false
            state.permission.texturizer = false

            // only continue if token exists
            if (token)
            {
                try {
                    // decode token
                    const decodedToken = jwtDecode(token)

                    // username state
                    state.username = decodedToken.sub

                    // developer state
                    state.developer = decodedToken.developer === 'true'

                    // permission state
                    for (let permission of decodedToken.permission)
                    {
                        // ignore case
                        const permissionIgnoreCase = permission.toLowerCase()

                        switch (permissionIgnoreCase)
                        {
                            case 'sketchurizer':
                                state.permission.sketchurizer = true
                                break
                            case 'modulator':
                                state.permission.modulator = true
                                break
                            case 'texturizer':
                                state.permission.texturizer = true
                                break
                        }
                    }

                    // store token
                    state.token = token
                }
                catch (error) {
                    console.error('token not valid')
                }
            }
            
            // update cookie 
            const cookies = useCookies(['token'])
            if (state.permanent) cookies.set('token', token, { expires: COOKIE_EXPIRES })
            else cookies.set('token', null, { expires: COOKIE_EXPIRES })
        },
        SET_PERMANENT(state, permanent)
        {
            state.permanent = permanent            
        },
        SET_MODE(state, mode)
        {
            state.design.mode = mode

            // update cookie 
            const cookies = useCookies(['mode'])
            cookies.set('mode', mode, { expires: COOKIE_EXPIRES })
        },
        SET_THEME(state, theme) 
        {
            state.design.theme = theme

            // update cookie 
            const cookies = useCookies(['theme'])
            cookies.set('theme', theme, { expires: COOKIE_EXPIRES })

            // update vuetify
            updateAccentColors(theme)
        },
        SET_VUETIFY(state, vuetify)
        {
            state.design.vuetify = vuetify
        }
    },
    getters: {
        keyDesignModeDefault: () => DESIGN_MODE_DEFAULT,
        keyDesignModeLight: () => DESIGN_MODE_LIGHT,
        keyDesignModeDark: () => DESIGN_MODE_DARK,
        keyDesignThemeDefault: () => DESIGN_THEME_DEFAULT,
        getVersion: state => state.version, 
        getAppname: state => state.appname,
        getLoadingProgress: state => {
            if(isNaN(state.loading.progress)) return 0
            if(state.loading.progress == -1) return -1
            return Math.floor(state.loading.progress / 0.1) * 0.1 // in steps of 10%
        },
        getToken: state => state.token,
        getUsername: state => state.username,
        getMode: state => state.design.mode,
        getTheme: state => state.design.theme,
        getVuetify: state => state.design.vuetify,
        isLoading: state => state.loading.active,
        isLoadingProgress: state => state.loading.progress >= 0,
        isInteractive: state => state.interactive,
        isExperimental: state => {
            if (state.developer) return state.experimental
            return false
        },
        isAuthenticated: state => state.token != null,
        isPermanent: state => state.permanent,
        isDeveloper: state => state.developer,
        isSketchurizer: state => state.permission.sketchurizer,
        isApps: state => state.permission.sketchurizer,
        isModulator: state => state.permission.modulator,
        isTexturizer: state => state.permission.texturizer,
        isPrototypes: state =>  state.permission.modulator || state.permission.texturizer
    },
    actions: {
        initializeApp({ dispatch, commit }, version)
        {
            // set app version
            commit('SET_VERSION', version)

            // get token
            const cookie_token = useCookies(['token']).get('token')
            if (typeof cookie_token !== 'undefined')
                commit('SET_TOKEN', cookie_token)

            // update design
            dispatch('updateDesign')
        },

        setAppname({ commit }, appname)
        {
            commit('SET_APPNAME', appname)
        },
        activateLoading({ commit })
        {
            commit('SET_LOADING', { active: true, progress: -1 })
        },
        deactivateLoading({ commit })
        {
            commit('SET_LOADING', { active: false, progress: -1 })
        },
        setLoading({ commit }, { active, progress }) 
        {
            commit('SET_LOADING', { active, progress })
        },
        setExperimental({ commit }, experimental) {
            commit('SET_EXPERIMENTAL', experimental)
        },
        setToken({ dispatch, commit }, token) {
            commit('SET_TOKEN', token)
        },
        setPermanent({ commit }, permanent)
        {
            commit('SET_PERMANENT', permanent)
        },
        setVuetify({ commit }, vuetify)
        {
            commit('SET_VUETIFY', vuetify)
        },

        // register
        registerUser({ dispatch, commit, getters }, { 
            email, 
            callbackSuccess = null, 
            callbackError = null 
        } = {}) 
        {
            const callbackSuccessWrapper = (json) => {
                if (json.success)
                {
                    if (callbackSuccess && typeof callbackSuccess === 'function') 
                        callbackSuccess()
                }
                else
                {
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(json.message)
                }
            }

            const callbackErrorWrapper = (error) => {
                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/registerUser', { 
                email, 
                mode: getters.getMode,
                theme: getters.getTheme,
                callbackSuccess: callbackSuccessWrapper, 
                callbackError: callbackErrorWrapper 
            }, { root: true })
        },

        // activate
        async activateUser({ dispatch, commit }, { 
            token, 
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            const callbackSuccessWrapper = (json) => {

                if (json.success)
                {
                    commit('SET_TOKEN', json.access_token)

                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess()
                }
                else
                {
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(json.message)
                }
            }

            const callbackErrorWrapper = (error) => {
                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/activateUser', { 
                token, 
                callbackSuccess: callbackSuccessWrapper, 
                callbackError: callbackErrorWrapper
            }, { root: true })
        },

        // deactivate 
        async deactivateUser({ dispatch, commit, getters }, { 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            const token = getters.getToken

            const callbackSuccessWrapper = (json) => {

                if (json.success)
                {
                    commit('SET_TOKEN', null)

                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess()
                }
                else
                {
                    if (callbackError && typeof callbackError === 'function')
                        callbackError('An unknown error has occured.')
                }
            }

            const callbackErrorWrapper = (error) => {

                commit('SET_TOKEN', null)

                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/deactivateUser', { 
                token, 
                callbackSuccess: callbackSuccessWrapper, 
                callbackError: callbackErrorWrapper
            }, { root: true })
        },
        
        // login
        async loginUser({ dispatch, commit }, { 
            username, 
            password, 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            const callbackSuccessWrapper = (json) => {

                if (json.success)
                {
                    commit('SET_TOKEN', json.access_token)
                    dispatch('updateDesign')

                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess()
                }
                else
                {
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(json.message)
                }
            }

            const callbackErrorWrapper = (error) => {
                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/tokenUser', { 
                username, 
                password, 
                callbackSuccess: callbackSuccessWrapper, 
                callbackError: callbackErrorWrapper
            }, { root: true })
        },

        // logout
        logoutUser({ commit }, { 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            commit('SET_TOKEN', null)

            if (callbackSuccess && typeof callbackSuccess === 'function')
                callbackSuccess()

            // there is no error case
        },

        // password forgot
        async passwordForgot({ dispatch }, { 
            username, 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            const callbackSuccessWrapper = (json) => {

                if (json.success)
                {
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess()
                }
                else
                {
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(json.message)
                }
            }

            const callbackErrorWrapper = (error) => {
                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/passwordForgot', {
                username,
                callbackSuccess: callbackSuccessWrapper,
                callbackError: callbackErrorWrapper
            }, { root: true })
        },

        // password reset
        async passwordReset({ dispatch }, { 
            token, 
            password, 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            dispatch('api/passwordChange', {
                token,
                password, 
                callbackSuccess,
                callbackError
            }, { root: true })
        },

        // password change
        async passwordChange({ dispatch, getters }, { 
            password, 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            const callbackErrorWrapper = (error) => {
                commit('SET_TOKEN', null)                

                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }


            dispatch('api/passwordChange', {
                token: getters.getToken,
                password, 
                callbackSuccess,
                callbackError: callbackErrorWrapper
            }, { root: true })
        },

        // user info
        async getUserInfo({ dispatch, getters }, { 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            const callbackSuccessWrapper = (json) => {
                if (json.success) 
                {
                    // information
                    const date = new Date(json.create_date)
                    const day = String(date.getUTCDate()).padStart(2, '0')
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0')
                    const year = date.getUTCFullYear()
                    const formattedDate = `${day}.${month}.${year}`

                    // statistics
                    const images = json.statistics.images_generation_count
                    const models = json.statistics.model_generation_count
                    let textures = 0
                    textures += Number(json.statistics.texture_generation_count)
                    textures += Number(json.statistics.texture_generic_generation_count)
                    textures += Number(json.statistics.texture_custom_generation_count)

                    // combined data
                    const data = {
                        since: formattedDate,
                        images,
                        models,
                        textures
                    }

                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(data)
                }
                else 
                {
                    if (callbackError && typeof callbackError === 'function')
                        callbackError()
                }
            }

            const callbackErrorWrapper = (error) => {
                commit('SET_TOKEN', null)                

                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/getUserInfo', {
                token: getters.getToken,
                callbackSuccess: callbackSuccessWrapper,
                callbackError: callbackErrorWrapper
            }, { root: true })
        },

        // get newsletter subscription
        async getNewsletterSubscription({ dispatch, getters }, { 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            const callbackSuccessWrapper = (json) => {
                if (json.success) 
                {
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(json)
                }
                else
                {
                    if (callbackError && typeof callbackError === 'function')
                        callbackError()
                }
            }

            const callbackErrorWrapper = (error) => {
                commit('SET_TOKEN', null)                

                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/getNewsletterSubscription', {
                token: getters.getToken,
                callbackSuccess: callbackSuccessWrapper,
                callbackError: callbackErrorWrapper
            }, { root: true })
        },

        // change newsletter subscription
        async changeNewsletterSubscription({ dispatch, getters }, { 
            subscribed, 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            const callbackErrorWrapper = (error) => {
                commit('SET_TOKEN', null)                

                if (callbackError && typeof callbackError === 'function')
                    callbackError('An unknown error has occured.')
            }

            dispatch('api/changeNewsletterSubscription', {
                token: getters.getToken,
                subscribed,
                callbackSuccess,
                callbackError: callbackErrorWrapper
            }, { root: true })
        },

        // update design
        async updateDesign({ dispatch, commit, getters }, { 
            callbackSuccess = null, 
            callbackError = null
        } = {})
        {
            // check if user is authenticated
            if (getters.isAuthenticated)
            {
                const callbackSuccessWrapper = (json) => {

                    // json.mode: 'Light' und 'Dark'
                    commit('SET_MODE', json.mode)
        
                    // json.theme: 0 .. 3
                    commit('SET_THEME', json.theme)

                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess()
                }

                const callbackErrorWrapper = (error) => {
                    commit('SET_TOKEN', null)                
    
                    if (callbackError && typeof callbackError === 'function')
                        callbackError('An unknown error has occured.')
                }
        
                dispatch('api/getUserDesign', {
                    token: getters.getToken,
                    callbackSuccess: callbackSuccessWrapper,
                    callbackError: callbackErrorWrapper
                }, { root: true })
            }

            // if user is not authenticated
            else
            {
                // cookies object
                const cookies = useCookies(['mode', 'theme'])


                //// MODE
    
                // variable for mode
                var mode = 'Light'

                // get cookie
                const cookie_mode = cookies.get('mode')
    
                // if cookie exists            
                if (typeof cookie_mode !== 'undefined' && (cookie_mode === 'Light' || cookie_mode === 'Dark'))
                {
                    mode = cookie_mode
                }
                // else, try system default
                else 
                {
                    var system_dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                    mode = system_dark ? 'Dark' : 'Light'
                }

                // set mode
                commit('SET_MODE', mode)
            

                //// THEME

                // variable for theme
                var theme = 0

                // get cookie
                const cookie_theme = cookies.get('theme')

                // if cookie
                if (typeof cookie_theme !== 'undefined' && cookie_theme >= 0 && cookie_theme <= 3)
                    theme = cookie_theme

                // set theme
                commit('SET_THEME', theme)


                // callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess()
            }
        },

        // change design
        async changeDesign({ dispatch, commit, getters }, {
            mode,
            theme,
            callbackSuccess = null,
            callbackError = null
        } = {})
        {
            // directly set mode and theme
            commit('SET_MODE', mode)
            commit('SET_THEME', theme)

            // check if user is logged in
            if (getters.isAuthenticated)
            {
                // set them but revert back when unsuccessful
                const callbackSuccessWrapper = (json) => {
                    if (json.success)
                    {
                        if (callbackSuccess && typeof callbackSuccess === 'function')
                            callbackSuccess()
                    }
                    else
                    {
                        if (callbackError && typeof callbackError === 'function')
                            callbackError()
                    }
                }

                const callbackErrorWrapper = (error) => {
                    commit('SET_TOKEN', null)                
    
                    if (callbackError && typeof callbackError === 'function')
                        callbackError('An unknown error has occured.')
                }
    
                dispatch('api/changeUserDesign', {
                    token: getters.getToken,
                    mode,
                    theme,
                    callbackSuccess: callbackSuccessWrapper,
                    callbackError: callbackErrorWrapper
                }, { root: true })
            }
        }
    }
}