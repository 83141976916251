// utils.js


//// FILES

// access file on server
export async function accessServerFile(path, callbackSuccess = null, callbackError = null) {
    try {
        // get response
        const response = await fetch(path)

        // error in case something goes wrong
        if (!response.ok) throw new Error(`unable to access server file: ${response.status}`);

        // extract text
        const text = await response.text()

        // only valid responses
        if (text) {
            // trigger callback
            if (callbackSuccess && typeof callbackSuccess === 'function')
                callbackSuccess(text)
        }
        else {
            // trigger callback
            if (callbackError && typeof callbackError === 'function')
                callbackError("unable to access server file")
        }
    }
    catch (error) {
        // error message
        console.error("unable to access offline resource: ", error)

        // trigger callback
        if (callbackError && typeof callbackError === 'function')
            callbackError(error)
    }
}

// access image on server
export async function accessServerImage(path, callbackSuccess = null, callbackError = null) {
    // load img as base64
    const getBase64 = async (url) => {

        const data = await fetch(url)
        const blob = await data.blob()

        return new Promise((resolve, reject) => {

            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                const base64 = reader.result

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(base64)

                resolve(base64)
            }
            reader.onerror = (errorEvent) => {
                // error message
                const error = new Error(`unable to access server image: ${errorEvent.target.error.message}`)

                // log error
                console.error(error)

                // reject
                reject(error)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            }
        })
    }

    // load image
    getBase64(path)
}

// access image on client 
export async function accessClientImage(file, callbackSuccess = null, callbackError = null) {
    const reader = new FileReader()

    reader.onload = function (event) {
        const base64 = event.target.result

        // trigger callback
        if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess(base64)
    }
    reader.onerror = function (error) {
        // error message
        console.error('unable to access client image: ', error)

        // trigger callback
        if (callbackError && typeof callbackError === 'function') {
            callbackError(error)
        }
    }

    // load image
    reader.readAsDataURL(file)
}


//// STRINGS

// shorten a string ot the given length
export function shorten(str, len) {
    // if string is too long, shorten it
    if (str.length > len)
        return str.substring(0, len - 3) + "..."

    // string does not require shortening
    return str
}

// format a string (i.e. capitalize individual words and remove special character)
export function format(str) {
    // add whitespaces in front of capital letters
    var stringWithSpaces = str.replace(/(?!^)([A-Z])/g, ' $1')

    // remove all special characters
    let stringWithoutSpecialChars = stringWithSpaces.replace(/[^a-zA-Z0-9\s]/g, ' ')

    // convert to array of words
    let words = stringWithoutSpecialChars.split(/\s+/)

    // capitalize first letter of every word
    let capitalizedWords = words.map(word => {
        if (word.length === 0) {
            return word
        }
        return word[0].toUpperCase() + word.substr(1).toLowerCase()
    });

    // combine array elements to string
    let finalString = capitalizedWords.join(' ')

    return finalString
}

// remove extension from filename string
export function removeExtension(filename) {
    // Split the filename into parts on the dot
    const parts = filename.split('.');

    // If there's no dot or only one part, return the filename as is
    if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
        return filename;
    }

    // Join the parts back together, excluding the last part (the extension)
    return parts.slice(0, -1).join('.');
}

// lookup text in dict
export function lookup(text, dict)
{
    // split into words
    const words = text.split(/\s+/)

    // find class mentioned in prompt
    for (let word of words) 
    {
        for (let [key, values] of Object.entries(dict)) 
        {
            if (values.includes(word))
                return key
        }
    }

    return null
}


//// MATH

// calculate Euclidean distance
export function calcEuclidean(vector1, vector2) {
    // at least two dimensions
    let distanceSquared = Math.pow(vector1.x - vector2.x, 2) +
        Math.pow(vector1.y - vector2.y, 2)

    // add third dimension if needed
    if (typeof vector1.z !== 'undefined' && typeof vector2.z !== 'undefined')
        distanceSquared += Math.pow(vector1.z - vector2.z, 2)

    // return the square root
    return Math.sqrt(distanceSquared)
}


//// COLORS

// export rgb values to hex string
export function rgbToHex(r, g, b, largerRange) {
    // helper function to adjust and convert one component
    const toHex = component => {
        if (!largerRange) component = Math.round((component * 255))
        if (component < 0) {
            component = 0
        } else if (component > 255) {
            component = 255
        }
        const hex = component.toString(16)
        return hex.length == 1 ? "0" + hex : hex
    }

    // convert each component and concatenate
    return "#" + toHex(r) + toHex(g) + toHex(b)
}


//// DEVICES

export function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return /android|avantgo|blackberry|iphone|ipad|ipod|iemobile|opera mini|mobile|palm|windows ce|windows phone|webos/i.test(userAgent)
}

export function isMobileResolution() {
    return window.matchMedia("(max-width: 750px)").matches
}