// vuex.js state for Sketchurizer

// window
const WINDOW_GUIDANCE = 'guidance'
const WINDOW_QUERY = 'query'
const WINDOW_VIEWER = 'viewer'
const WINDOW_DEFAULT = WINDOW_GUIDANCE

// widgets
const WIDGET_SKETCH = 'sketch'
const WIDGET_UPLOAD = 'upload'
const WIDGET_DEFAULT = WIDGET_SKETCH

// guidance
const GUIDANCE_INPUT_SKETCH = 'sketch'
const GUIDANCE_INPUT_UPLOAD = 'upload'
const GUIDANCE_INPUT_NONE = 'none'
const GUIDANCE_INPUT_DEFAULT = GUIDANCE_INPUT_NONE
const GUIDANCE_STRENTH_DEFAULT = 0.5

// prompt
const PROMPT_POSITIVE_DEFAULT = ''
const PROMPT_NEGATIVE_DEFAULT = ''

export const sketchurizer = {
    namespaced: true,
    state: {
        update: false,

        toastActive: false,
        toastText: '',

        hints: {},

        sketch: null,
        sketchBackground: null,
        upload: null,
        images: null,
        model: null,

        selected: -1,

        activeWindow: WINDOW_DEFAULT,
        activeWidget: WIDGET_DEFAULT,
        
        guidanceInput: GUIDANCE_INPUT_DEFAULT,
        guidanceStrength: GUIDANCE_STRENTH_DEFAULT,
        promptPositive: PROMPT_POSITIVE_DEFAULT,
        promptNegative: PROMPT_NEGATIVE_DEFAULT,
    },
    mutations: {
        SET_UPDATE(state, update) {
            state.update = update
        },

        SET_TOAST_ACTIVE(state, active) {
            state.toastActive = active
        },
        SET_TOAST_TEXT(state, text) {
            state.toastText = text
        },

        SET_HINT(state, { hint, value }) {
            state.hints = {
              ...state.hints,
              [hint]: value
            }
        },

        SET_SKETCH(state, sketch) {
            if (state.sketch === sketch) return
            state.sketch = sketch
            state.update = true
        },
        SET_SKETCH_BACKGROUND(state, background) {
            if (state.sketchBackground === background) return
            state.sketchBackground = background
            state.update = true
        },
        SET_UPLOAD(state, upload) {
            if (state.upload === upload) return
            state.upload = upload
            state.update = true
        },
        SET_IMAGES(state, images) {
            if (state.images === images) return
            state.images = images
            state.update = true
        },
        SET_MODEL(state, model) {
            if (state.model === model) return
            state.model = model
            state.update = false
        },

        SET_SELECTED(state, selected) {
            if (state.selected === selected) return
            state.selected = selected
            state.model = null
            state.update = true
        },

        SET_ACTIVE_WINDOW(state, window) {
            state.activeWindow = window
        },
        SET_ACTIVE_WIDGET(state, widget) {
            state.activeWidget = widget
        },
    
        SET_GUIDANCE_INPUT(state, input) {
            if (state.guidanceInput === input) return
            state.guidanceInput = input
            state.update = true
        },
        SET_GUIDANCE_STRENGTH(state, strength) {
            if (state.guidanceStrength === strength) return
            state.guidanceStrength = strength
            state.update = true
        },
        SET_PROMPT_POSITIVE(state, prompt) {
            if (state.promptPositive === prompt) return
            state.promptPositive = prompt
            state.update = true
        },
        SET_PROMPT_NEGATIVE(state, prompt) {
            if (state.promptNegative === prompt) return
            state.promptNegative = prompt
            state.update = true
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowGuidance: () => WINDOW_GUIDANCE,
        keyWindowQuery: () => WINDOW_QUERY,
        keyWindowViewer: () => WINDOW_VIEWER,
        keyWidgetSketch: () => WIDGET_SKETCH,
        keyWidgetUpload: () => WIDGET_UPLOAD,
        keyGuidanceInputSketch: () => GUIDANCE_INPUT_SKETCH,
        keyGuidanceInputUpload: () => GUIDANCE_INPUT_UPLOAD,
        keyGuidanceInputNone: () => GUIDANCE_INPUT_NONE,
        getToastText: state => state.toastText,
        getHint: (state) => (hint) => {
            return state.hints[hint] || false
        },
        getSketch: state => state.sketch,
        getSketchBackground: state => state.sketchBackground,
        getUpload: state => state.upload,
        getImages: state => state.images,
        getModel: state => state.model,
        getSelected: state => state.selected,
        getSelectedImage: (state, getters) => () => {
            // if guidance image is directly selected
            if (state.selected < -1) {
                if (getters.isGuidanceInputSketch)
                    return state.sketch.substr(22)
                if (getters.isGuidanceInputUpload)
                    return state.upload.substr(22)
                return null
            }

            if (state.images && state.selected > -1) {
                return state.images[state.selected]
            }
            return null
        },
        getActiveWindow: state => state.activeWindow,
        getActiveWidget: state => state.activeWidget,
        getGuidanceInput: state => state.guidanceInput,
        getGuidanceStrength: state => state.guidanceStrength,
        getPromptPositive: state => state.promptPositive,
        getPromptNegative: state => state.promptNegative,
        isUpdate: state => state.update,
        isToastActive: state => state.toastActive,
        isSketch: state => state.sketch !== null,
        isSketchBackground: state => state.sketchBackground !== null,
        isUpload: state => state.upload !== null,
        isImages: state => state.images !== null,
        isModel: state => state.model !== null,
        isImageSelected: (state) => () => {
            if (state.selected > -1) return true
            if (state.selected < -1 && state.guidanceInput !== GUIDANCE_INPUT_NONE) return true
            return false
        },
        isOutdated: state => state.outdated,
        isWindowDefault: state => state.activeWindow === WINDOW_DEFAULT,
        isWindowGuidance: state => state.activeWindow === WINDOW_GUIDANCE,
        isWindowQuery: state => state.activeWindow === WINDOW_QUERY,
        isWindowViewer: state => state.activeWindow === WINDOW_VIEWER,
        isWidgetDefault: state => state.activeWidget === WIDGET_DEFAULT,
        isWidgetSketch: state => state.activeWidget === WIDGET_SKETCH,
        isWidgetUpload: state => state.activeWidget === WIDGET_UPLOAD,
        isGuidanceInputSketch: state => state.guidanceInput === GUIDANCE_INPUT_SKETCH,
        isGuidanceInputUpload: state => state.guidanceInput === GUIDANCE_INPUT_UPLOAD,
        isGuidanceInputNone: state => state.guidanceInput === GUIDANCE_INPUT_NONE,
        isGuidanceImageSelected: (state, getters) => {
            if (getters.isGuidanceInputNone) return false
            return state.selected === -2
        }
    },
    actions: {
        setUpdate({ commit }, update) {
            commit('SET_UPDATE', update)
        },
        setToast({ commit }, text) {
            commit('SET_TOAST_TEXT', text)
            commit('SET_TOAST_ACTIVE', true)
        },
        setToastActive({ commit }, active) {
            commit('SET_TOAST_ACTIVE', active)
        },
        setHint({ commit }, payload) {
            commit('SET_HINT', payload)
        },
        setToastText({ commit }, text) {
            commit('SET_TOAST_ACTIVE', text)
        },
        setSketch({ commit }, sketch) {
            commit('SET_SKETCH', sketch)
        },
        setSketchBackground({ commit }, background) {
            commit('SET_SKETCH_BACKGROUND', background)
        },
        setUpload({ commit }, upload) {
            commit('SET_UPLOAD', upload)
        },
        setImages({ commit }, images) {
            commit('SET_IMAGES', images)
        },
        setModel({ commit }, model) {
            commit('SET_MODEL', model)
        },
        setSelected({ commit }, selected) {
            commit('SET_SELECTED', selected)
        },
        setActiveWindow({ commit }, window) {
            commit('SET_ACTIVE_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToGuidance({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_GUIDANCE)
        },
        setActiveWindowToQuery({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_QUERY)
        },
        setActiveWindowToViewer({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_VIEWER)
        },
        setActiveWidget({ commit }, widget) {
            commit('SET_ACTIVE_WIDGET', widget)
        },
        setGuidanceInput({ commit }, input) {
            commit('SET_GUIDANCE_INPUT', input)
        },
        setGuidanceStrength({ commit }, strength) {
            commit('SET_GUIDANCE_STRENGTH', strength)
        },
        setPromptPositive({ commit }, prompt) {
            commit('SET_PROMPT_POSITIVE', prompt)
        },
        setPromptNegative({ commit }, prompt) {
            commit('SET_PROMPT_NEGATIVE', prompt)
        },
        setExample({ commit }, example) {
            commit('SET_SKETCH_BACKGROUND', example.sketch())
            commit('SET_GUIDANCE_INPUT', GUIDANCE_INPUT_SKETCH)
            commit('SET_GUIDANCE_STRENGTH', example.guidanceStrength())
            commit('SET_PROMPT_POSITIVE', example.promptPositive())
            commit('SET_PROMPT_NEGATIVE', example.promptNegative())
            commit('SET_IMAGES', example.images())
            commit('SET_SELECTED', example.selected())
            commit('SET_MODEL', example.model())
            commit('SET_UPDATE', false)
        }
    }
}