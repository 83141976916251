import * as THREE from 'three'
import { format } from '@/utils'
import { Mesh } from '@/classes/data/Mesh.js'
import { GeometryFactory } from '@/classes/factory/GeometryFactory.js'
import { MaterialFactory } from '@/classes/factory/MaterialFactory.js'
import { ColorSingleMaterial } from '@/classes/data/material/ColorSingleMaterial'

/*
 * Mesh Factory Class
 */
export class MeshFactory {
    // available primitive shapes
    static primitivePlane = 'plane'
    static primitiveCircle = 'circle'
    static primitiveRing = 'ring'
    static primitiveCube = 'cube'
    static primitiveSphere = 'sphere'
    static primitiveCylinder = 'cylinder'
    static primitiveCone = 'cone'
    static primitiveTorus = 'torus'

    static newMeshFromInstance(model, meshInstance) {
        const newMesh = meshInstance.clone(model)

        GeometryFactory.newGeometryFromInstance(newMesh, meshInstance.getActiveGeometry())
        MaterialFactory.newMaterialFromInstance(newMesh, meshInstance.getActiveMaterial())

        return newMesh
    }

    static newMeshFromPrimitive(model, primitive) {
        // create mesh
        const newMesh = new Mesh(model, "New Mesh")

        // create primitive instance
        var buffer = null
        switch (primitive) {
            case MeshFactory.primitivePlane:
                buffer = new THREE.PlaneGeometry()
                break
            case MeshFactory.primitiveCircle:
                buffer = new THREE.CircleGeometry(1, 32)
                break
            case MeshFactory.primitiveRing:
                buffer = new THREE.RingGeometry(0.5, 1, 32)
                break
            case MeshFactory.primitiveCube:
                buffer = new THREE.BoxGeometry()
                break
            case MeshFactory.primitiveSphere:
                buffer = new THREE.SphereGeometry(0.5, 32, 32)
                break
            case MeshFactory.primitiveCylinder:
                buffer = new THREE.CylinderGeometry(0.5, 0.5, 1, 32, 32)
                break
            case MeshFactory.primitiveCone:
                buffer = new THREE.ConeGeometry(0.5, 1, 32, 32)
                break
            case MeshFactory.primitiveTorus:
                buffer = new THREE.TorusGeometry(0.5, 0.2, 32, 32)
                break
        }

        // create geometry
        GeometryFactory.newGeometryFromBuffer(newMesh, buffer)

        // create material
        MaterialFactory.newMaterialFromClass(newMesh, ColorSingleMaterial)

        // add mesh to model
        model.addMesh(newMesh)

        return newMesh
    }

    // initialize mesh from JSON
    static newMeshfromJSON(json, model) {
        // ensure json exists
        if (json == null) return

        // get mesh name
        const name = json.name ? format(json.name) : "Unnamed"

        // create mesh
        const mesh = new Mesh(model, name)

        // flag mesh as not ready
        mesh.setReady(false)

        // set geometries
        mesh.nonreactive.geometries = GeometryFactory.newGeometriesfromJSON(json.geometry, mesh)

        // set materials
        mesh.nonreactive.materials = MaterialFactory.newMaterialsfromJSON(json.material, json.geometry?.color, mesh)

        // flag mesh as ready
        mesh.setReady(true)

        return mesh
    }

    // export mesh to json
    static toJSON(mesh) {
        // create empty json
        let json = {}

        // set name
        json.name = mesh.reactive.name

        // set geometry
        json.geometry = GeometryFactory.toJSON(mesh.getActiveGeometry())

        // set material
        json.material = MaterialFactory.toJSON(mesh.getActiveMaterial())

        // return serialized class as json
        return json
    }

    static segmentMesh(model, mesh, selection, copy) {
        // create new mesh
        const newMesh = new Mesh(model, "New Mesh")

        // get active geometry
        const geometry = mesh.getActiveGeometry()

        // create new geometry from selection
        GeometryFactory.newGeometryFromInstanceBySelection(newMesh, geometry, selection)

        // remove selection from geometry (and all geometries - not just the active one)
        if (!copy) {
            //TODO check for not active geometry 
            // if all geometries depend on the first one [0], they should auto update
            GeometryFactory.removeGeometryFromInstanceBySelection(geometry, selection)
        }

        // create new material
        MaterialFactory.newMaterialFromClass(newMesh, ColorSingleMaterial)

        // add mesh to model
        model.addMesh(newMesh)

        // return new mesh
        return newMesh
    }

    static combineMeshes(model, firstMesh, secondMesh) {
        // get default geometries
        const firstGeometry = firstMesh.getDefaultGeometry()
        const secondGeometry = secondMesh.getDefaultGeometry()

        // new name
        const name = firstMesh.getName() + ' + ' + secondMesh.getName()

        // create new mesh
        const newMesh = new Mesh(model, name)

        // combine geometries
        GeometryFactory.combineGeometries(newMesh, firstGeometry, secondGeometry)

        // create new material
        MaterialFactory.newMaterialFromClass(newMesh, ColorSingleMaterial)

        // add mesh to model
        model.addMesh(newMesh)

        // return combined mesh
        return newMesh
    }
}