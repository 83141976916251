// vuex.js state of api

import { accessServerFile, accessServerImage, accessClientImage, lookup } from '@/utils.js'


// API User
const API_USER_TOKEN = '/api/user/token'
const API_USER_REGISTER = '/api/user/register'
const API_USER_ACTIVATE = '/api/user/activate'
const API_USER_DEACTIVATE = '/api/user/deactivate'
const API_USER_PASSWORD_FORGOT = '/api/user/password/forgot'
const API_USER_PASSWORD_CHANGE = '/api/user/password/change'
const API_USER_NEWSLETTER = '/api/user/newsletter'
const API_USER_INFO = '/api/user/info'
const API_USER_DESIGN = '/api/user/design'

// API Parameter
const API_PARAMETER_COLOR_GENERATE = '/api/parameter/color/generate'

// API Image/Layer
const API_IMAGE_ID = '/api/image/'
const API_IMAGE_FROMTEXT_GENERATE = '/api/layer/from-text/generate' // upgraded to layer
const API_IMAGE_FROMDRAWING_GENERATE = '/api/image/from-drawing/generate' 
const API_IMAGE_FROMDEPTH_GENERATE = '/api/image/from-depth/generate'
const API_IMAGE_FROMIMAGE_GENERATE = '/api/image/from-image/generate' 
const API_IMAGE_SEGMENTATION_GENERATE = '/api/image/segmentation/generate'

// API Texture
const API_TEXTURE_ID = '/api/texture/'
const API_TEXTURE_ALBEDO_GENERATE = '/api/texture/albedo/generate'
const API_TEXTURE_DIFFUSE_GENERATE = '/api/texture/diffuse/generate'
const API_TEXTURE_DISPLACEMENT_GENERATE = '/api/texture/displacement/generate'
const API_TEXTURE_BUMP_GENERATE = '/api/texture/bump/generate'
const API_TEXTURE_NORMAL_GENERATE = '/api/texture/normal/generate'

// API Model
const API_MODEL_ID = '/api/model/'
const API_MODEL_FROMIMAGE_GENERATE = '/api/model/from-image/generate' 
const API_MODEL_FROMSCRIPT_GENERATE = '/api/model/from-script/generate'
const API_MODEL_ANYTHINGTOOBJ_CONVERT = '/api/model/anything-to-obj/convert'
const API_MODEL_OBJTOANYTHING_CONVERT = '/api/model/obj-to-anything/convert'

// API Log
const API_LOG_BUG = '/api/log/bug'
const API_LOG_VOTE = '/api/log/vote'
const API_LOG_EVENT = '/api/log/event'
const API_LOG_RATING = '/api/log/rating'
const API_LOG_FEEDBACK = '/api/log/feedback'


// vuex store as wrapper to the GenerIO API
export const api = {
    namespaced: true,
    state: {
        active: true,
        materialComponents: [],
        lastComponent: 0,
        votes: {}
    },
    mutations: {
        // mutation to set api active or disabled
        SET_ACTIVE(state, active) {
            state.active = active
        },
        // mutation to add material component
        ADD_COMPONENT(state, component) {
            state.materialComponents.push(component)
        },
        // mutation to remove material component
        REMOVE_COMPONENT(state, componentId) {
            const index = state.materialComponents.findIndex(component => component.id === componentId)
            if (index !== -1) state.materialComponents.splice(index, 1)
        },
        // mutation to add vote
        SET_VOTE(state, { key, value }) {
            state.votes[key] = value
        }
    },
    getters: {
        keyTypeColor: () => 'color',
        keyTypeTexture: () => 'texture',
        newComponent: state => (type, tag, prompt) => {

            // create new material component
            const component = {
                id: state.lastComponent++,
                type: type,         // type of material component (color or texture)
                tag: tag,           // component "source" tag (import, generic, or wood)
                prompt: prompt,     // unique query for generated material component
                ready: false,       // true, when material component is generated
                reload: false,      // true, when textures need reload
                data: null          // component data
            }

            // configure material component (type dependent)
            switch (type) 
            {
                // color
                case 'color':
                    component.data = {
                        ambientColor: { r: 255, g: 255, b: 255 },
                        diffuseColor: { r: 255, g: 255, b: 255 },
                        specularColor: { r: 255, g: 255, b: 255 },
                        specularIntensity: 30,
                        emissiveColor: { r: 0, g: 0, b: 0 },
                        emissiveIntensity: 0,
                        roughness: 1.0,
                        metalness: 0.0
                    }
                    break

                // texture
                case 'texture':
                    component.data = {
                        albedoMap: null,
                        bumpMap: null,
                        normalMap: null
                    }
                    break
            }

            // return material component
            return component
        },
        getActive: state => state.active,
        getVersion: state => state.version,
        getComponents: state => (type, tag) => {
            return state.materialComponents.filter(
                component => component.type === type && component.tag === tag
            )  
        },
        getComponent: state => (id) => {
            return state.materialComponents.find(
                component => component.id === id
            )
        },
        getComponentByPrompt: state => (type, tag, prompt) => {
            return state.materialComponents.find(component => 
                component.type === type &&
                component.tag === tag && 
                JSON.stringify(component.prompt) === JSON.stringify(prompt)
            )
        },
        getComponentCopy: (state, getters) => (id) => {
            const component = getters.getComponent(id)
            const { data, ...rest } = component
            const componentCopy = {
              ...rest,
              ...data
            }
            return componentCopy
        },
        getDefaultComponent: state => (type, tag) => {
            return state.materialComponents.find(
                component => component.type === type && component.tag === tag
            )
        },
        getDefaultComponentCopy: (state, getters) => (type, tag) => {
            const component = getters.getDefaultComponent(type, tag)
            const { data, ...rest } = component
            const componentCopy = {
              ...rest,
              ...data
            }
            return componentCopy
        },
        getVote: state => (key) => {
            return state.votes[key]
        },
        isVote: state => (key) => key in state.votes
    },
    actions: {

        //// INITIALIZATION

        // initialize the api (loads default textures)
        async initializeAPI({ state, commit, getters })
        {
            // reset material components
            state.materialComponents = []

            // default texture templates
            const defaultTemplates = [
                {
                    type: 'color',
                    tag: 'generic'
                },
                { 
                    type: 'texture', 
                    tag: 'generic', 
                    path: './textures/generic/default-generic.png' 
                },
                { 
                    type: 'texture',
                    tag: 'wood', 
                    path: './textures/wood/default-wood.png' 
                },
                { 
                    type: 'texture',
                    tag: 'sand', 
                    path: './textures/sand/default-sand.png' 
                },
                { 
                    type: 'texture',
                    tag: 'brick', 
                    path: './textures/brick/default-brick.png' 
                }
            ]

            // iterate through all templates
            defaultTemplates.forEach(template => {

                // new material component
                const component = getters.newComponent(template.type, template.tag, null)

                // add material component to store
                commit('ADD_COMPONENT', component)

                // color material component
                if (template.type === 'color') 
                {
                    // flag component as ready
                    component.ready = true
                }

                // texture material component
                else if (template.type === 'texture')
                {
                    // load texture
                    accessServerImage(template.path, function(base64) {

                        // persist the requested data
                        component.data.albedoMap = base64

                        // flag texture as reload necessary
                        component.reload = true

                        // flag component as ready
                        component.ready = true
                    })
                }
            })
        },

        //// USER MANAGEMENT

        // token
        // 
        // success: false
        // 'Username or password incorrect.'
        // 'Account not activated yet.'
        // 'Account has been deleted.'
        async tokenUser({ commit }, { username, password, callbackSuccess, callbackError })
        {
            const payload = {
                username, 
                password
            }

            accessAPI(
                API_USER_TOKEN, 
                payload, 
                'POST', 
                callbackSuccess, 
                callbackError) 
        },

        // register
        // 
        // success: false
        // 'The provided email is not valid.'
        // 'Account with email exists already.'
        async registerUser({ commit }, { email, mode, theme, callbackSuccess, callbackError }) 
        {
            const payload = {
                email,
                mode,
                theme
            }

            accessAPI(
                API_USER_REGISTER, 
                payload, 
                'POST', 
                callbackSuccess, 
                callbackError) 
        },

        // activate
        //
        // success: false
        // 'The activation link is not valid.'
        async activateUser({ commit }, { token, callbackSuccess, callbackError })
        {
            const payload = {
                token
            }

            accessAPI(
                API_USER_ACTIVATE, 
                payload, 
                'PUT', 
                callbackSuccess, 
                callbackError) 
        },

        // deactivate 
        //
        // 401: unauthorized
        async deactivateUser({ commit }, { token, callbackSuccess, callbackError })
        {
            accessSecureAPI(
                API_USER_DEACTIVATE, 
                token,
                null, 
                'PUT', 
                callbackSuccess, 
                callbackError) 
        },

        // password forgot
        //
        // success: false
        // 'If the account exists, you will receive an email with a password reset link.'
        async passwordForgot({ commit }, { username, callbackSuccess, callbackError })
        {
            const payload = {
                accountname: username 
            }

            accessAPI(
                API_USER_PASSWORD_FORGOT, 
                payload, 
                'POST', 
                callbackSuccess, 
                callbackError) 
        },

        // password change
        //
        // 401: unauthorized
        async passwordChange({ commit }, { token, password, callbackSuccess, callbackError })
        {
            const payload = {
                new_password: password
            }

            accessSecureAPI(
                API_USER_PASSWORD_CHANGE, 
                token,
                payload, 
                'PUT', 
                callbackSuccess, 
                callbackError) 
        },

        // get user info
        //
        // 401: unauthorized
        async getUserInfo({ commit }, { token, callbackSuccess, callbackError })
        {
            accessSecureAPI(
                API_USER_INFO, 
                token,
                null, 
                'GET', 
                callbackSuccess, 
                callbackError) 
        },

        // get newsletter subscription
        // 
        // 401: unauthorized
        async getNewsletterSubscription({ commit }, { token, callbackSuccess, callbackError })
        {
            accessSecureAPI(
                API_USER_NEWSLETTER,
                token,
                null,
                'GET',
                callbackSuccess,
                callbackError
            )
        },

        // change newsletter subscription
        // 
        // 401: unauthorized
        async changeNewsletterSubscription({ commit }, { token, subscribed, callbackSuccess, callbackError })
        {
            const payload = {
                subscribed
            }

            accessSecureAPI(
                API_USER_NEWSLETTER,
                token,
                payload,
                'POST',
                callbackSuccess,
                callbackError
            )
        },
        
        // get user design
        //
        // 401: unauthorized
        async getUserDesign({ commit }, { token, callbackSuccess, callbackError })
        {
            accessSecureAPI(
                API_USER_DESIGN,
                token,
                null,
                'GET',
                callbackSuccess,
                callbackError
            )
        },

        // change user design
        //
        // 401: unauthorized
        async changeUserDesign({ commit }, { token, mode, theme, callbackSuccess, callbackError })
        {
            const payload = {
                mode, 
                theme
            }

            accessSecureAPI(
                API_USER_DESIGN,
                token,
                payload,
                'PUT',
                callbackSuccess,
                callbackError
            )
        },

        //// COMPONENTS

        // remove material component
        removeComponent({ commit }, componentId) {
            // remove material component to store
            commit('REMOVE_COMPONENT', componentId)
        },


        //// PARAMETER

        // generate color
        async generateColor({ rootState, commit, getters, dispatch }, 
            { 
                prompt, 
                callbackSuccess = null, 
                callbackError = null 
            })
        {
            // new material component for the generated color
            const component = getters.newComponent('color', 'generic', prompt)

            // add material component to store
            commit('ADD_COMPONENT', component)

            // function for error handling
             const callbackErrorWrapper = (error) => {

                // remove from list again
                dispatch('removeComponent', component.id)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            } 

            // function for success handling
            const callbackSuccessWrapper = (result) => {

                // extract json
                const json = result.response

                // check if json is valid
                if (json)
                {
                    // get diffuse color
                    if (json.Diffuse && json.Diffuse.r && json.Diffuse.g && json.Diffuse.b)
                    {
                        // large value range (0.255)
                        const largerRange = json.Diffuse.r > 1 || 
                                            json.Diffuse.g > 1 || 
                                            json.Diffuse.b > 1

                        component.data.diffuseColor = {
                            r: largerRange ? json.Diffuse.r : Math.floor(json.Diffuse.r * 255),
                            g: largerRange ? json.Diffuse.g : Math.floor(json.Diffuse.g * 255),
                            b: largerRange ? json.Diffuse.b : Math.floor(json.Diffuse.b * 255),
                        }
                    }

                    // get emissive color
                    if (json.Emissive && json.Emissive.r && json.Emissive.g && json.Emissive.b)
                    {
                        // large value range (0.255)
                        const largerRange = json.Emissive.r > 1 || 
                                            json.Emissive.g > 1 || 
                                            json.Emissive.b > 1

                        component.data.emissiveColor = {
                            r: largerRange ? json.Emissive.r : Math.floor(json.Emissive.r * 255),
                            g: largerRange ? json.Emissive.g : Math.floor(json.Emissive.g * 255),
                            b: largerRange ? json.Emissive.b : Math.floor(json.Emissive.b * 255),
                        }
                    }

                    // get emissive exponent
                    if (json['Emissive Strength'])
                    {
                        const exp = Number(json['Emissive Strength'])
                        component.data.emissiveIntensity = Math.max(Math.min(exp, 1), 0)
                    }

                    // get advanced attributes
                    if (json.Roughness) 
                        component.data.roughness = json.Roughness
                    if (json.Metalness) 
                        component.data.metalness = json.Metalness

                    // set component as ready
                    component.ready = true

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(component)
                }
                else 
                    errorHandling("Color could not be parsed.")
            }

            // api is active
            if (rootState.api.active)
            {
                // prepare server request
                const payload = {
                    query: component.prompt
                }

                // send server request
                await accessAPI(
                    API_PARAMETER_COLOR_GENERATE, 
                    payload, 
                    'POST',
                    callbackSuccessWrapper,
                    callbackErrorWrapper
                )
            }

            // api is inactive
            else
            {
                // color lookup for offline processing
                const colorLookup = {
                    "brick": ["brick", "ziegel"],
                    "concrete": ["stone", "stein", "concrete"],
                    "glass": ["glass"],
                    "gold": ["gold"],
                    "granite": ["granite"],
                    "leather": ["leather", "textile", "stoff", "textil", "leder"],
                    "marble": ["marble"],
                    "plastic": ["plastic"],
                    "steel": ["steel", "metal", "metall", "eisen", "iron", "stahl"]
                }

                // get filename for prompt
                const filename = lookup(component.prompt, colorLookup)

                // if class was found
                if (filename) 
                {
                    // path to json
                    const path = `./colors/${filename}.json`

                    accessServerFile(path, function(text) {

                        // trigger callback
                        if (callbackSuccess && typeof callbackSuccess === 'function')
                            callbackSuccessWrapper({ response: JSON.parse(text) })

                    }, callbackError)     
                }
                // if class was not found
                else {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackErrorWrapper("no fitting color found")
                }
            }
        },


        //// IMAGE

        async generateImageFromText({ rootState, commit, getters }, {
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1, 
            steps = 6,
            cfg = 2,
            seeds = [ -1 ],
            callbackSuccess = null, 
            callbackError = null,
            callbackProgress = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from text has no offline support')

                return
            }

            // generate images
            accessAPIFiles(
                API_IMAGE_FROMTEXT_GENERATE,
                {
                    positive,
                    negative,
                    resolution,
                    denoising, 
                    steps,
                    cfg,
                    seeds,
                    user: rootState.app.username
                },
                'image',
                (assets) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                },
                callbackProgress
            )
        },

        async generateImageFromDrawing({ rootState, commit, getters }, {
            image,
            guidance = 1.0,
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 6,
            cfg = 2,
            seeds = [ -1 ],
            callbackSuccess = null, 
            callbackError = null,
            callbackProgress = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from drawing has no offline support')

                return
            }

            // generate images
            accessAPIFiles(
                API_IMAGE_FROMDRAWING_GENERATE,
                {
                    image,
                    guidance,
                    positive,
                    negative,
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds,
                    user: rootState.app.username
                },
                'image',
                (assets) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                },
                callbackProgress
            )
        },

        async generateImageFromDepth({ rootState, commit, getters }, {
            image,
            guidance = 1.0,
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 6,
            cfg = 2,
            seeds = [ -1 ],
            callbackSuccess = null, 
            callbackError = null,
            callbackProgress = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from depth has no offline support')

                return
            }

            // generate images
            accessAPIFiles(
                API_IMAGE_FROMDEPTH_GENERATE,
                {
                    image,
                    guidance,
                    positive,
                    negative,
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds,
                    user: rootState.app.username
                },
                'image',
                (assets) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                },
                callbackProgress
            )
        },

        async generateImageFromImage({ rootState, commit, getters }, {
            image,
            guidance = 1.0,
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 6,
            cfg = 2,
            seeds = [ -1 ],
            callbackSuccess = null, 
            callbackError = null,
            callbackProgress = null 
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from image has no offline support')

                return
            }
            
            // generate images
            accessAPIFiles(
                API_IMAGE_FROMIMAGE_GENERATE,
                {
                    image: image,
                    guidance,
                    positive,
                    negative,
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds,
                    user: rootState.app.username
                },
                'image',
                (assets) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                },
                callbackProgress
            )
        },

        async segmentImage({ rootState, commit, getters }, { 
            image, 
            callbackSuccess = null, 
            callbackError = null 
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('segment image has no offline support')

                return
            }

            accessAPIFiles(
                API_IMAGE_SEGMENTATION_GENERATE,
                {
                    image: image, 
                    user: rootState.app.username
                },
                'image',
                (assets, additional) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets, additional)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                }
            )
        },


        //// TEXTURE

        // import texture
        async importTexture({ state, commit, getters, dispatch }, 
            { 
                file, 
                callbackSuccess=null, 
                callbackError=null 
            })
        {
            // new material component for the imported texture
            const component = getters.newComponent('texture', 'import', null)

            // add material component to store
            commit('ADD_COMPONENT', component)

            // function for error handling
            const errorHandling = (error) => {

                // remove from list again
                dispatch('removeComponent', component.id)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            } 

            // function for success handling
            const successHandling = (base64) =>  {
                // extract the image data
                const base64Data = base64.split(',')[1] || ''

                // ensure the image is not empty
                if (base64Data.length < 100) {
                    const error = new Error('imported texture is empty')
                    errorHandling(error)
                    return
                }

                // persist the requested data
                component.data.albedoMap = base64
                component.data.bumpMap = null
                component.data.normalMap = null

                // flag texture as reload necessary
                component.reload = true

                // flag texture as ready
                component.ready = true

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(component)
            }

            // load image texture
            accessClientImage(
                file, 
                successHandling,
                errorHandling
            )
        },

        // generate texture
        async generateTexture({ rootState, commit, getters, dispatch }, 
            { 
                prompt,
                type = 'albedo',
                tag = 'generic', 
                resolution = 512, 
                denoising = 1,
                steps = 6,
                cfg = 2,
                seeds = [ -1 ],
                callbackSuccess=null, 
                callbackError=null 
            }) 
        {
            // new material component for the generated texture
            const component = getters.newComponent('texture', tag, prompt)

            // add material component to array
            commit('ADD_COMPONENT', component)

            // function for error handling
            const callbackErrorWrapper = (error) => {

                // remove material component from list again
                dispatch('removeComponent', component.id)
                console.log(component.id)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            }

            // function for success handling
            const callbackSuccessWrapper = (component) => {

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(component)
            }

            // api is active
            if (rootState.api.active) 
            {
                // API resource
                const API_RESOURCE = type == 'albedo' ? API_TEXTURE_ALBEDO_GENERATE : API_TEXTURE_DIFFUSE_GENERATE

                // create payload
                const payload = {
                    user: rootState.app.username,
                    positive: prompt.positive,
                    negative: prompt.negative ? prompt.negative : '',
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds
                }

                // custom texture material
                if (tag != 'generic')
                {
                    payload.image = prompt.svg
                    payload.guidance = 1
                }

                accessAPIFiles(
                    API_RESOURCE,
                    payload,
                    'texture',
                    function(assets) 
                    {
                        // persist the requested data
                        component.data.albedoMap = 'data:image/png;base64,' + assets[0]
                        component.data.bumpMap = null
                        component.data.normalMap = null

                        // flag texture as reload necessary
                        component.reload = true

                        // flag texture as ready
                        component.ready = true

                        // trigger callback
                        if (callbackSuccessWrapper && typeof callbackSuccessWrapper === 'function')
                            callbackSuccessWrapper(component)
                    },
                    callbackErrorWrapper
                )
            }

            // api is inactive
            else 
            {
                // texture lookup for offline processing
                const textureLookup = {
                    "wood": ["wood", "holz", "baum", "tree"],
                    "brick": ["brick", "ziegel"],
                    "sand": ["sand", "ground", "erde"],
                    "grass": ["grass", "green"],
                    "stone": ["stone", "stein", "kiesel", "gravel"],
                    "metal": ["metal", "metall", "eisen", "iron"],
                    "textile": ["textile", "stoff", "textil"]
                }

                // get filename for prompt
                const filename = lookup(prompt.positive, textureLookup)

                // if class was found
                if (filename) 
                {
                    // path to image
                    const rand = Math.floor((Math.random() * 4) + 1)
                    const path = `./textures/generic/${filename}/${rand}.png`

                    accessServerImage(path, function(base64) {

                        // persist the requested data
                        component.data.albedoMap = base64
                        component.data.bumpMap = null
                        component.data.normalMap = null

                        // flag texture as reload necessary
                        component.reload = true

                        // flag texture as ready
                        component.ready = true

                        // trigger callback
                        if (callbackSuccessWrapper && typeof callbackSuccessWrapper === 'function')
                            callbackSuccessWrapper(component)

                    }, callbackError)         
                }
                // if class was not found
                else {
                    // trigger callback
                    if (callbackErrorWrapper && typeof callbackErrorWrapper === 'function')
                        callbackErrorWrapper("no fitting texture found")
                }
            }
        },

        // generate texture
        async generateMaps({ rootState, commit, getters, dispatch }, 
            {
                component, 
                type = 'displacement',
                normal = true,
                callbackSuccess=null, 
                callbackError=null 
            }) 
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('segment image has no offline support')
    
                return
            }

            // API resource
            const API_RESOURCE = type == 'displacement' ? API_TEXTURE_DISPLACEMENT_GENERATE : API_TEXTURE_BUMP_GENERATE

            // payload 
            const payload = {
                image: component.data.albedoMap.substring(22),
                resolution: 512,
                user: 'testuser'
            }

            const finish = () => {
                // flag texture as reload necessary
                component.reload = true

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(component)
            }

            // TODO FIX ME -- new function
            accessAPIFiles(
                API_RESOURCE, 
                payload, 
                'texture',
                function(assets) 
                {
                    // persist the requested data
                    component.data.bumpMap = 'data:image/png;base64,' + assets[0]

                    /*
                    if (normal)
                    {
                        // payload 
                        const payloadNormal = {
                            texture: assets[0]
                        }

                        accessAPIFiles(
                            API_TEXTURE_NORMAL_GENERATE,
                            payloadNormal, 
                            'texture',
                            function(normalAssets) 
                            {
                                // persist the requested data
                                component.data.normalMap = 'data:image/png;base64,' + normalAssets[0]

                                finish()
                            },
                            callbackError
                        )
                    }
                    else*/ 
                    finish()
                },
                callbackError
            )
        },


        //// MODEL

        async generateModelFromImage({ rootState, commit, getters }, {
            image,
            resolution = 256,
            threshold = 25,
            callbackSuccess = null, 
            callbackError = null 
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate model from image has no offline support')
                
                return
            }
                
            accessAPIFiles(
                API_MODEL_FROMIMAGE_GENERATE,
                {
                    resolution,
                    threshold,
                    image, 
                    user: rootState.app.username
                },
                'model',
                (assets) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                }
            )
        },

        async generateModelFromScript({ rootState, commit, getters }, {})
        {

        },

        async convertModelAnythingToObj({ rootState, commit, getters }, {})
        {

        },

        async convertModelObjToAnything({ rootState, commit, getters }, {})
        {

        },


        //// LOG

        // fetch or get vote 
        async fetchVote({ rootState, commit, getters }, key) 
        {
            // if vote exists in state
            const vote = getters.getVote(key)
            if (vote) return

            // api not active
            if (!rootState.api.active)
            {
                // just assume the user has not voted yet
                commit('SET_VOTE', { key, value: false })
                return
            }

            // prepare server request
            const payload = {
                user_name: rootState.app.username,
                app_name: rootState.app.appname,
                version: rootState.app.version,
                topic: key
            }

            // function to handle existing voting
            const callbackSuccess = (result) => {
                const voting = result.voting.toLowerCase()
                if (voting === 'true')
                    commit('SET_VOTE', { key, value: true })
                else 
                    commit('SET_VOTE', { key, value: false })
            }

            // function to handle not existing voting
            const callbackError = (error) => {
                // the user has not voted yet
                commit('SET_VOTE', { key, value: false })
            }

            // fetch vote flag from server
            await accessAPI(
                API_LOG_VOTE, 
                payload, 
                'GET', 
                callbackSuccess,
                callbackError
            )
        },

        // log voting
        async logVote({ rootState, commit, getters }, { key, flag }) 
        {
            // parameter flag is readonly
            let newFlag = flag

            // if flag parameter is undefined, toggle existing state
            if (newFlag === undefined) 
            {
                // get vote state
                newFlag = getters.getVote(key)
        
                // if vote not defined yet, we introduce it as voted for (true)
                if (newFlag === undefined)
                    newFlag = true
                else
                    newFlag = !newFlag
            }
 
            // api not active
            if (!rootState.api.active)
            {
                console.error("vote not send to server")
                console.log("vote_key: " + key + " vote_flag: " + newFlag)
                return
            }
        
            // prepare server request
            const payload = {
                version: rootState.app.version,
                app_name: rootState.app.appname,
                user_name: rootState.app.username,
                topic: key,
                voting: newFlag
            }
            await accessAPI(API_LOG_VOTE, payload, 'PUT')
        
            // change vote in state
            commit('SET_VOTE', { key, value: newFlag })
        },
        
        // log event
        async logEvent({ rootState }, { name, description }) 
        {
            // api not active
            if (!rootState.api.active)
            {
                console.error("event not send to server")
                console.log("event_name: " + name + " event_description: " + description)
                return
            }

            // prepare server request
            const payload = {
                user_name: rootState.app.username,
                app_name: rootState.app.appname,
                version: rootState.app.version,
                event_name: name,
                event_description: description
            }
            await accessAPI(API_LOG_EVENT, payload)
        },

        // log bug
        async logBug({ rootState }, { name, description })
        {
            // api not active
            if (!rootState.api.active)
            {
                console.error("bug not send to server")
                console.log("bug_name: " + name + " bug_description: " + description)
                return
            }

            // prepare server request
            const payload = {
                user_name: rootState.app.username,
                app_name: rootState.app.appname,
                version: rootState.app.version,
                event_name: name,
                event_description: description
            }
            await accessAPI(API_LOG_BUG, payload)
        },

        // log rating
        async logRating({ rootState }, { topic, rating })
        {
            // api not active
            if (!rootState.api.active)
            {
                console.error("rating not send to server")
                console.log("rating_topic: " + topic + " rating_value: " + rating)
                return
            }

            // prepare server request
            const payload = {
                user_name: rootState.app.username,
                app_name: rootState.app.appname,
                version: rootState.app.version,
                topic,
                rating
            }
            await accessAPI(API_LOG_RATING, payload)
        },

        // log feedback
        async logFeedback({ rootState }, { topic, feedback }) 
        {
            // api not active
            if (!rootState.api.active)
            {
                console.error("feedback not send to server")
                console.log("feedback_topic: " + topic + " feedback_value: " + feedback)
                return
            }

            // prepare server request
            const payload = {
                user_name: rootState.app.username,
                app_name: rootState.app.appname,
                version: rootState.app.version,
                topic,
                feedback
            }
            await accessAPI(API_LOG_FEEDBACK, payload)
        }
    }
}


//// UNIVERSAL FUNCTIONS

// access the GenerIO API
async function accessSecureAPI(url, token, payload, method='POST', callbackSuccess=null, callbackError=null) 
{
    try {
        // content of request
        const content = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }

        // payload when post 
        if (method === 'POST' || method === 'PUT')
            content.body = JSON.stringify(payload)
        // payload when get
        else if (method === 'GET' && payload && Object.keys(payload).length > 0) 
        {
            // serialize payload into query parameters
            const queryParams = new URLSearchParams(payload).toString()

            // append query parameters to URL
            url += `?${queryParams}`
        }

        // await response
        const response = await fetch(url, content)

        // throw error in case of trouble
        if (!response.ok)   
            throw { status: response.status, statusText: response.statusText }

        // returned json object
        const json = await response.json()

        // trigger callback
        if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess(json)
    }
    catch (error) 
    {
        // get error code
        const statusCode = error.status || 'Unknown'

        // not a true error case (not unexpected)
        if (statusCode !== 404)
            console.error("unable to access the GenerIO API: ", statusCode)

        // trigger callback
        if (callbackError && typeof callbackError === 'function')
            callbackError(error)
    }
}


// access the GenerIO API
async function accessAPI(url, payload, method='POST', callbackSuccess=null, callbackError=null) 
{
    try {
        // content of request
        const content = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            }
        }

        // payload when post 
        if (method === 'POST' || method === 'PUT')
            content.body = JSON.stringify(payload)
        // payload when get
        else if (method === 'GET' && payload && Object.keys(payload).length > 0) 
        {
            // serialize payload into query parameters
            const queryParams = new URLSearchParams(payload).toString()

            // append query parameters to URL
            url += `?${queryParams}`
        }

        // await response
        const response = await fetch(url, content)

        // throw error in case of trouble
        if (!response.ok)   
            throw { status: response.status, statusText: response.statusText }

        // returned json object
        const json = await response.json()

        // trigger callback
        if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess(json)
    }
    catch (error) 
    {
        // get error code
        const statusCode = error.status || 'Unknown'

        // not a true error case (not unexpected)
        if (statusCode !== 404)
            console.error("unable to access the GenerIO API: ", statusCode)

        // trigger callback
        if (callbackError && typeof callbackError === 'function')
            callbackError(error)
    }
}

async function accessAPIFiles(url, payload, type, callbackSuccess=null, callbackError=null, callbackProgress=null)
{
    // API resource path
    let API_RESOURCE = ''
    switch(type)
    {
        case 'image':
            API_RESOURCE = API_IMAGE_ID
            break
        case 'texture':
            API_RESOURCE = API_TEXTURE_ID
            break
        case 'model':
            API_RESOURCE = API_MODEL_ID
            break
        default:
            return
    }

    // function that waits for the generated texture to be available
    const onlineAwait = (list) => {

        // get next id
        let next = list.find(item => item.value === false)

        // process next id
        if (next)
        {
            // send request
            accessAPI(
                API_RESOURCE + next.id, 
                {}, 
                'GET', 
                function (json) 
                {
                    // response not readonly, try again later
                    if (json != null && json.status == 'success')
                    {
                        // store asset
                        next.asset = json.asset

                        // store additional 
                        next.additional = json.additional

                        // mark image as loaded
                        next.value = true

                        // trigger progress
                        if (callbackProgress && typeof callbackProgress === 'function')
                            callbackProgress(json.asset, json.additional)

                        // recursive callback
                        setTimeout(() => onlineAwait(list), 0)
                    }
                    else if (json != null && json.status == 'running')
                    {
                        setTimeout(() => onlineAwait(list), 500)
                    }
                    else 
                    {
                        // trigger callback
                        if (callbackError && typeof callbackError === 'function')
                            callbackError('internal server error')
                    }
                },
                callbackError
            )
        }
        else
        {
            // cleanup resources online
            onlineCleanup(list)

            // trigger callback
            if (callbackSuccess && typeof callbackSuccess === 'function')
                callbackSuccess(
                    list.map(item => item.asset),
                    list.map(item => item.additional)
                )
        }
    }
    
    // function that frees the allocated memory on the server
    const onlineCleanup = (list) => {

        for (let next of list) 
        {
            // send request
            accessAPI(
                API_RESOURCE + next.id, 
                {}, 
                'DELETE', 
                null, 
                callbackError)
        }
    }
    
    // send request
    accessAPI(
        url, 
        payload, 
        'POST', 
        function (json) {

            // check that we have a list of ids
            if (json && json.ids && json.ids.length > 0)
            {
                // get list of ids
                let list = json.ids

                // add false values to each (to indicate their status)
                list = list.map(id => {
                    return { id: id, value: false }
                })

                onlineAwait(list)
            }
        }, 
        callbackError
    )
}